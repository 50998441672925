<template>
    <div class="items-center flex text-gray-800">
        <div v-if="element.relative" class="flex items-center text-primary mr-2 rounded-full px-2 gap-4  bg-blue-100 shadow">
            relative
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-700 mt-1">
                <path
                    d="M7.90976 10.648C7.90976 11.3494 8.17676 12.0508 8.71083 12.5848L10.1528 14.0268C10.4261 14.3002 10.8696 14.3002 11.1429 14.0268C11.4164 13.7536 11.4164 13.3098 11.1429 13.0367L9.70095 11.5947C9.17885 11.0726 9.17907 10.223 9.7011 9.70094C10.2232 9.17906 11.0726 9.17906 11.5946 9.70094L13.0366 11.1429C13.3099 11.4164 13.7534 11.4164 14.0267 11.1429C14.3003 10.8697 14.3003 10.4259 14.0267 10.1528L12.5848 8.71082C11.5167 7.64275 9.77882 7.64275 8.71075 8.71097C8.17683 9.24497 7.90976 9.9464 7.90976 10.648Z"
                    fill="#231F20"
                />
                <path
                    d="M17.199 17.1988C18.2667 16.1308 18.2671 14.3932 17.199 13.325L15.7571 11.883C15.4838 11.6095 15.0403 11.6095 14.767 11.883C14.4934 12.1562 14.4934 12.6 14.767 12.8731L16.2089 14.3151C16.731 14.8372 16.7308 15.6868 16.2088 16.2089C15.6867 16.7307 14.8372 16.7307 14.3152 16.2089L12.8733 14.7669C12.5999 14.4934 12.1565 14.4934 11.8832 14.7669C11.7464 14.9035 11.678 15.0828 11.678 15.262C11.678 15.4411 11.7464 15.6205 11.8832 15.757L13.3251 17.199C14.3932 18.267 16.131 18.267 17.199 17.1988Z"
                    fill="#231F20"
                />
                <path d="M14.892 14.8919C15.1656 14.6187 15.1656 14.1749 14.892 13.9017L12.0081 11.0179C11.7348 10.7443 11.2913 10.7443 11.018 11.0179C10.8812 11.1544 10.8129 11.3337 10.8129 11.5129C10.8129 11.6921 10.8812 11.8714 11.018 12.008L13.9019 14.8919C14.1752 15.1653 14.6186 15.1653 14.892 14.8919Z" fill="#231F20" />
                <path d="M10.2227 6.09124C10.5152 6.09124 10.7524 5.8541 10.7524 5.56157C10.7524 5.26904 10.5152 5.03189 10.2227 5.03189C9.93016 5.03189 9.69302 5.26904 9.69302 5.56157C9.69302 5.8541 9.93016 6.09124 10.2227 6.09124Z" fill="#282828" />
                <path
                    d="M11.4409 1.05935H10.7524V0.529673C10.7524 0.237135 10.5152 0 10.2227 0C9.93015 0 9.69302 0.237135 9.69302 0.529673V1.05935H7.283V0.529673C7.283 0.237135 7.04587 0 6.75333 0C6.46079 0 6.22366 0.237135 6.22366 0.529673V1.05935H3.84013V0.529673C3.84013 0.237135 3.60299 0 3.31046 0C3.01792 0 2.78078 0.237135 2.78078 0.529673V1.05935H2.11869C0.950445 1.05935 0 2.00979 0 3.17804V11.4409C0 12.6092 0.950445 13.5596 2.11869 13.5596H6.17069C6.46323 13.5596 6.70036 13.3225 6.70036 13.03C6.70036 12.7374 6.46323 12.5003 6.17069 12.5003H2.11869C1.53457 12.5003 1.05935 12.0251 1.05935 11.4409V3.17804C1.05935 2.59391 1.53457 2.11869 2.11869 2.11869H2.78078V2.64837C2.78078 2.9409 3.01792 3.17804 3.31046 3.17804C3.60299 3.17804 3.84013 2.9409 3.84013 2.64837V2.11869H6.22366V2.64837C6.22366 2.9409 6.46079 3.17804 6.75333 3.17804C7.04587 3.17804 7.283 2.9409 7.283 2.64837V2.11869H9.69302V2.64837C9.69302 2.9409 9.93015 3.17804 10.2227 3.17804C10.5152 3.17804 10.7524 2.9409 10.7524 2.64837V2.11869H11.4409C12.0251 2.11869 12.5003 2.59391 12.5003 3.17804V6.19717C12.5003 6.48971 12.7374 6.72685 13.03 6.72685C13.3225 6.72685 13.5596 6.48971 13.5596 6.19717V3.17804C13.5596 2.00979 12.6092 1.05935 11.4409 1.05935Z"
                    fill="#282828"
                />
                <path d="M7.91861 6.09124C8.21114 6.09124 8.44828 5.8541 8.44828 5.56157C8.44828 5.26904 8.21114 5.03189 7.91861 5.03189C7.62608 5.03189 7.38894 5.26904 7.38894 5.56157C7.38894 5.8541 7.62608 6.09124 7.91861 6.09124Z" fill="#282828" />
                <path d="M5.61453 8.39532C5.90706 8.39532 6.14421 8.15817 6.14421 7.86564C6.14421 7.57311 5.90706 7.33597 5.61453 7.33597C5.322 7.33597 5.08486 7.57311 5.08486 7.86564C5.08486 8.15817 5.322 8.39532 5.61453 8.39532Z" fill="#282828" />
                <path d="M3.31046 6.09124C3.60299 6.09124 3.84013 5.8541 3.84013 5.56157C3.84013 5.26904 3.60299 5.03189 3.31046 5.03189C3.01793 5.03189 2.78078 5.26904 2.78078 5.56157C2.78078 5.8541 3.01793 6.09124 3.31046 6.09124Z" fill="#282828" />
                <path d="M3.31046 8.39532C3.60299 8.39532 3.84013 8.15817 3.84013 7.86564C3.84013 7.57311 3.60299 7.33597 3.31046 7.33597C3.01793 7.33597 2.78078 7.57311 2.78078 7.86564C2.78078 8.15817 3.01793 8.39532 3.31046 8.39532Z" fill="#282828" />
                <path d="M3.31046 10.6994C3.60299 10.6994 3.84013 10.4623 3.84013 10.1697C3.84013 9.87719 3.60299 9.64005 3.31046 9.64005C3.01793 9.64005 2.78078 9.87719 2.78078 10.1697C2.78078 10.4623 3.01793 10.6994 3.31046 10.6994Z" fill="#282828" />
                <path d="M5.61453 10.6994C5.90706 10.6994 6.14421 10.4623 6.14421 10.1697C6.14421 9.87719 5.90706 9.64005 5.61453 9.64005C5.322 9.64005 5.08486 9.87719 5.08486 10.1697C5.08486 10.4623 5.322 10.6994 5.61453 10.6994Z" fill="#282828" />
                <path d="M5.61453 6.09124C5.90706 6.09124 6.14421 5.8541 6.14421 5.56157C6.14421 5.26904 5.90706 5.03189 5.61453 5.03189C5.322 5.03189 5.08486 5.26904 5.08486 5.56157C5.08486 5.8541 5.322 6.09124 5.61453 6.09124Z" fill="#282828" />
            </svg>
        </div>
        <div v-else class="items-center flex text-gray-800">
            <div class="flex items-center text-primary mr-2 rounded-full px-2 bg-blue-100 shadow" title="Valid Period ">
                <span class="mr-2 text-gray-800 font-semibold">{{ element.valid_gap }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-800">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                </svg>
            </div>
            <div class="flex items-center text-primary rounded-full px-2 bg-blue-100 shadow" title="History">
                <span class="mr-2 text-gray-800 font-semibold">{{ element.history }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-800">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GapInfo",
    props: {
        element: {
            type: Object,
            default: () => ({
                history: 0,
                valid_gap: 0,
            }),
        },
    },
};
</script>

<style lang="scss" scoped></style>
